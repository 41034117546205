.container {
  position: relative;

  &:hover {
    .bell {
      transition: 150ms;
      color: #b3b3b3;
    }
  }
}

.bell {
  font-size: 22px;
  color: #d1d1d1;
}

.circle {
  position: absolute;
  font-size: 10px;
  top: 9px;
  right: 8px;
  color: #e24a4a;
  box-shadow: 0px 0px 4px rgba(164, 75, 75, 0.25);
}

.cancel {
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  margin-left: auto !important;
  font-size: 12px;
  border-radius: 24px;
  color: #767676;
  cursor: pointer;

  &:hover {
    background: #dddddd;
  }
}

.notifications_container {
  &:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 65px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.5) 46.35%, #ffffff 100%);
  }
}

.notification {
  > div {
    @media screen and (max-width: 576px) {
      .cancel {
        align-self: center;
        width: 24px;
        min-width: 24px;
        height: 24px;
        font-size: 12px;
      }
    }

    &:hover {
      background-color: #f3f3f3;
      border-radius: 8px;
      cursor: pointer;

      .cancel {
        opacity: 1;
      }
    }
  }

  &:nth-last-child(2) {
    > hr {
      display: none;
    }
  }
}
