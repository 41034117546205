.collection {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #6fbfe5;
  width: 20px;
  height: 20px;
  background: #dbeff9;
  border-radius: 4px;
}

.snippet {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #966ace;
  width: 20px;
  height: 20px;
  background: #e1d5f1;
  border-radius: 4px;
}

.addButton {
  height: 40px;
  padding: 8px 24px;
  width: 96px;
  margin: 0px;
  font-size: 14px;
  box-shadow: 0px 1px 4px rgba(14, 87, 72, 0.25);

  i {
    font-size: 12px;
  }

  @media screen and (max-width: 1023px) {
    position: fixed;
    bottom: 56px;
    right: 32px;
    width: 64px;
    height: 64px;
    background: #55bca7;
    box-shadow: 0px 1px 30px rgba(14, 87, 72, 0.25);
    border-radius: 70px;

    i {
      font-size: 22px;
    }

    p {
      display: none;
    }
  }
}