.searchBar,
.content {
  width: calc(100% - 300px);

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
}

.searchBar {
  transition: width 0.3s;
}

.angles {
  position: absolute;
  z-index: 3;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 70px;
  left: 290px;
  font-size: 8px;
  height: 20px;
  width: 20px;
  border-radius: 50px;
  color: #fff;
  background: #6355bc;

  &:hover {
    background: #3d308a;
  }

  &.hidden_menu {
    transition: 0.3s;
    transform: rotateY(180deg);
    left: 62px;

    ~.content {
      width: calc(100% - 74px);

      @media screen and (max-width: 1023px) {
        width: 100vw;
      }

      .searchBar {
        transition: width 0.3s;
        width: calc(100% - 74px);

        @media screen and (max-width: 1023px) {
          width: 100vw;
        }
      }
    }
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.link_container {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  border-radius: 4px;
  padding: 8px 10px;
  color: #717171;
  transition: 150ms;

  @media screen and (max-width: 1023px) {
    padding: 12px 12px 20px;
    border-bottom: 4px solid;
    border-color: transparent;
    border-radius: 0;
  }

  &.active,
  &:hover {
    background: #f3f3f3;
    color: #464646;

    @media screen and (max-width: 1023px) {
      background: transparent;
      border-color: #55bca7;
    }
  }
}