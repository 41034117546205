.link_container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  height: 32px;
  gap: 8px;
  border-radius: 4px;
  background: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #464646;
  transition: 150ms;

  .feature_icon {
    display: none;

    @media screen and (max-width: 1023px) {
      display: flex;
    }
  }

  &:hover {
    background-color: #F2F2F2;

    .feature_icon {
      display: inline-flex;
    }
  }

  &.active {
    background-color: #EDEDED;
  }
}