.link_container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 8px;
  gap: 12px;
  width: 100%;
  height: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #282828;
  border: 1px solid transparent;
  border-radius: 8px;
  cursor: pointer;
  transition: none;

  .kebab_menu_icon {
    display: none;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #767676;
    cursor: pointer;
    width: 24px;
    height: 24px;

    @media screen and (max-width: 1023px) {
      display: flex;
    }

    &:hover {
      transition: 150ms;
      color: #2b70b9;
    }
  }

  .grip_icon {
    display: none;
    align-self: center;
    cursor: grab;
    color: #767676;
    font-size: 14px;
    margin-left: 6px;

    &:hover {
      color: #2b70b9;
    }
  }

  .items_count {
    color: #767676;

    @media screen and (max-width: 1023px) {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #f3f3f3;

    .kebab_menu_icon {
      display: flex;
    }

    .grip_icon {
      @media screen and (min-width: 576px) {
        display: block;
      }
    }
  }

  &.active {
    color: #2b70b9;
    background: #f3f8fe;
    border: 1px solid #d5e2f1;

    &>.kebab_menu_icon,
    .items_count {
      color: #2b70b9;
    }

    .kebab_menu_icon,
    .grip_icon {
      color: #2b70b9;
    }
  }
}

.icon_workspaces {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #2b70b9;
  border-radius: 50px;
  min-width: 32px;
  height: 32px;
  color: #fff;
}

.icon_utils {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  height: 28px;
  min-width: 28px;
  margin: 2px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(96, 96, 96, 0.25);
  border-radius: 8px;
  color: #2b70b9;
}

.navigation_container {
  overflow: visible;

  @media screen and (max-height: 700px) {
    overflow-y: auto;
    min-height: 48px;
    height: 160px;

    &.noscroll {
      overflow-y: hidden;
      min-height: 50px;
    }
  }

  &.noscroll {
    overflow: hidden;
  }
}

.workspace_drag_element {
  width: 275px;
  display: block;
  border-radius: 8px;
  position: absolute;
  top: -500%;

  &>* {
    background: #fff;

    .grip_icon {
      display: block;
    }
  }
}