@use '../../../../../assets/styles/font.scss';

.icon {
  color: rgba(16, 16, 16, 0.35);
  font-size: 16px;
}

.search {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 24px;
  font-size: 16px;
  color: #767676;
}

.cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  min-width: 24px;
  font-size: 16px;
  color: #BABABA;
}

.search_input {
  &:focus {

    ~div {
      .search {
        &:hover {
          background-color: #F3F3F3;
          border-radius: 4px;
          color: #2B70B9;
        }
      }

      .tag {
        transition: 150ms;
        background-color: #E24A4A;
      }

      .cancel {
        &:hover {
          background: #F3F8FE;
          border-radius: 4px;
          color: #767676;
        }
      }
    }
  }
}