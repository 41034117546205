.breadcrumb {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;

  p {
    color: #BABABA;
  }

  i {
    color: #BABABA;
  }

  &.active {
    p {
      color: #464646;
    }

    i {
      color: #9D9EB5;
    }
  }
}