.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  min-width: 24px;
  height: 24px;
  color: #767676;
  cursor: pointer;

  &:hover {
    transition: 150ms;
    background: #EAEAEA;
    border-radius: 4px;
    color: #424242;
  }
}

.avatar {
  font-size: 16px;
  color: #8f8f8f
}