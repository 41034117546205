@use '../../assets/styles/font.scss';

.error_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
  position: absolute;
  right: 24px;
  top: 24px;
  width: 374px;
  @media screen and (max-width: 576px) {
    right: 0;
    align-items: center;
    width: 100%;
  }
}

.error {
  z-index: 99999;
  transition: 0.3s ease;
  animation: show_slide 0.3s ease forwards;

  @keyframes show_slide {
    0% {
      margin: -20px 0 0 0;
    }

    100% {
      margin: 0;
    }
  }
}
