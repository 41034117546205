@use '../../../assets/styles/font.scss';

.container {
  width: 100%;
  height: 100%;
  font-family: font.$mainFont;
}

.label_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 8px;
  padding-bottom: 24px;
  padding-top: 0px;
  border-bottom: 1px solid #ededed;

  >p {
    margin: 0px;
    font-family: font.$mainFont;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
  }

  >i {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    font-size: 20px;
    background: #f3f3f3;
    color: rgba(16, 16, 16, 0.5);
    border-radius: 4px;

    &.folder {
      color: #ecc76a;
      font-size: 18px;
      padding: 7px;

      &_move {
        @extend .folder;
        color: #2b70b9;
      }
    }

    &.trash {
      color: #bc5555;
      font-size: 20px;
      padding: 7px;
    }

    &.rename {
      color: #5755bc;
      font-size: 18px;
      padding: 7px;
    }

    &.share {
      color: #55a3bc;
    }
  }
}

.shortcuts {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #d3d3d3;
  gap: 8px;
  margin-left: auto;
}

.facebook {
  font-size: 16px;
  cursor: pointer;
  color: #4267b2;
}

.twitter {
  font-size: 16px;
  cursor: pointer;
  color: #00acee;
}

.linkedin {
  font-size: 16px;
  cursor: pointer;
  color: #0072b1;
}

.short_button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid #d3d3d3;
  background-color: transparent;
  color: #d3d3d3;
  border-radius: 2px;
}

.command {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(85, 188, 167, 0.15);
  border: 1px solid #55bca7;
  border-radius: 2px;
  color: #55bca7;
}

.ctrl {
  @extend .command;
  width: 40px;
  font-size: 12px;
}

.middle_shortcut {
  @extend .short_button;
  width: 40px;
  font-size: 12px;
}

.input {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px 16px;
  margin-bottom: 16px;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  background: #f3f3f3;
  opacity: 0.8;
  border-radius: 4px;
  border: none;
  outline: none;
  font-family: font.$mainFont;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  resize: none;
}

.input_bookmark {
  @extend .input;
  min-height: 64px;
}

.folders {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 24px;
  padding: 0px;
  width: 100%;
  max-height: 92px;
  overflow-y: auto;
  gap: 12px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 10px;

    &-track {
      background: #f3f3f3;
      border-radius: 25px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 25px;

    &:hover {
      background: #878585;
    }
  }
}

.folder_button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 16px;
  height: 40px;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  cursor: pointer;

  >p {
    margin: 0px;
    font-family: font.$mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #282828;
    gap: 10px;
  }

  >i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    color: #ecc76a;
  }

  .pin {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    border-radius: 50px;
    color: #fff;
    background-color: #2b70b9;
    padding: 2px;
    top: 20px;
    left: 32px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
  }
}

.folder_active {
  background: #2b70b9;

  >p {
    color: #ffffff;
  }

  >i {
    color: #ffffff;
  }
}

.folder_unbutton {
  @extend .folder_button;
  cursor: initial;
  margin-right: auto;
  margin-bottom: 12px;
  margin-top: 16px;
}

.add_folder_button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #dadada;

  >i {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    font-size: 62px;
    cursor: pointer;
  }

  >p {
    margin: 0;
    font-family: font.$mainFont;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
  }
}

.delete_container_bookmark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  margin-top: 16px;
  gap: 16px;
  width: 100%;
  height: 88px;
  border-radius: 4px;
  background: #f3f3f3;

  >p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: font.$mainFont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    max-height: 44px;
    color: #282828;
  }

  >img {
    border-radius: 6px;
    width: 96px;
    height: 56px;
    object-fit: cover;
    background-color: #ffffff;
  }
}

.person_button_box {
  @extend .folders;
  gap: 8px;
  margin-top: 16px;
  max-height: 96px;
  padding-top: 8px;
}

.person_button {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  height: 40px;
  gap: 12px;
  background: #f3f3f3;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  >img {
    width: 28px;
    height: 28px;
    object-fit: cover;
    border-radius: 50px;
  }

  >p {
    margin: 0;
    font-family: font.$mainFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }

  .check {
    position: absolute;
    font-size: 14px;
    color: #55bca7;
    top: -8px;
    right: -8px;
    background-color: #fff;
    border-radius: 50px;
  }
}

.email_selected {
  border: 1px solid #55bca7;
}

.emails {
  @extend .folders;
  column-gap: 8px;
  row-gap: 12px;
  max-height: 80px;
}

.email_button {
  position: relative;
  @extend .folder_button;
  height: 31px;
  padding-right: 38px;
  cursor: initial;

  .delete_email {
    position: absolute;
    font-size: 12px;
    color: #bababa;
    cursor: pointer;
    right: 8px;
  }
}

.error {
  margin-right: auto;
  margin-top: -15px;
  font-size: 12px;
  color: #bc5555;
  margin-bottom: 12px;
}

.folderIcon {
  font-size: 14px;
  background: -webkit-gradient(linear, left top, left bottom, from(#ecc76a), to(#ffbd00));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.linkIcon {
  font-size: 15px;
  background: -webkit-gradient(linear, left top, left bottom, from(#8fd3f4), to(#00a1ff));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.renameIcon {
  font-size: 15px;
  background: -webkit-gradient(linear, left top, left bottom, from(#55bca7), to(#269c84));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.workspace_icon {
  font-size: 15px;
  background: -webkit-gradient(linear, left top, left bottom, from(#00a0ff), to(#2a77da));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}