@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600;700&display=swap');

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

i.fa,
i.fas {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter';
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}

body .react-calendar {
  border: none;
  box-shadow: 0px 0px 16px rgba(101, 101, 101, 0.25);
  border-radius: 8px;
  padding: 16px;
}

#google_auth_btn>div>div>div {
  height: 48px;
  z-index: 2;
}

#google_auth_btn>div>iframe {
  height: 48px !important;
  width: 100% !important;
  z-index: 1;
  margin: 0 !important;
}

.drag-element-wrapper {
  position: absolute;
  top: -500%;
  width: 180px;
}

.drag-element-wrapper>* {
  transform: scale(0.6) rotate(10deg);
}